import axios from "@/api";

export const noDP =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const state = {
  user: null,
  patientId: null,
  bloodGlucoseRecords: null,
  patientsList: null,
  patientProfile: null,
  profilePicture: null,
  userUpdatePersonalInfo: null,
  userUpdateMedicalInfo: null,
  patientProgressScore: null,
  allDocumentUploads: [],
  profilePercentage: null,
};

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getPatientId: (state) => {
    return state.patientId;
  },
  getUserProfilePicture: (state) => {
    return state.profilePicture;
  },
  getUserBloodGlucoseRecords: (state) => {
    return state.bloodGlucoseRecords;
  },
  getPatientsList: (state) => {
    return state.patientsList;
  },
  getPatientProfile: (state) => {
    return state.patientProfile;
  },
  getUserUpdatePersonalInfo: (state) => {
    return state.userUpdatePersonalInfo;
  },
  getUserUpdateMedicalInfo: (state) => {
    return state.userUpdateMedicalInfo;
  },
  getPatientProgressScore: (state) => {
    return state.patientProgressScore;
  },
  getAllDocumentUploads: (state) => {
    return state.allDocumentUploads;
  },
  getProfilePercentage: (state) => {
    return state.profilePercentage;
  },
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setPatientId(state, payload) {
    state.patientId = payload;
  },
  setBloodGlucoseRecords(state, payload) {
    state.bloodGlucoseRecords = payload;
  },

  setPatientsList(state, payload) {
    state.patientsList = payload;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  setPatientProfile(state, payload) {
    state.patientProfile = payload;
  },
  setProfilePicture(state, payload) {
    state.profilePicture = payload;
  },

  setUserUpdatePersonalInfo(state, payload) {
    state.userUpdatePersonalInfo = payload;
  },

  setUserUpdateMedicalInfo(state, payload) {
    state.userUpdateMedicalInfo = payload;
  },

  setPatientProgressScore(state, payload) {
    state.patientProgressScore = payload;
  },
  setAllDocumentUploads(state, payload) {
    state.allDocumentUploads = payload;
  },
  setProfilePercentage(state, payload) {
    state.profilePercentage = payload;
  },
};

const actions = {
  // fetch patient glucose level
  async fetchBloodGlucoseRecords({ commit }) {
    try {
      // https://api.turbomedics.com/data/patient/glucose_level_list?page=1
      const response = await axios.get(`/data/patient/glucose_level_list`);
      commit("setBloodGlucoseRecords", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching glucose records:", error);
    }
  },

  // fetch list of all patients
  async fetchPatientsList({ commit, rootState }, payload) {
    const isAdmin = rootState.auth.isAdmin;
    if (isAdmin) {
      try {
        const response = await axios.get(
          `/data/patients${payload ? `?page=${payload}` : ""}`
        );
        commit("setPatientsList", response.data);
        return response.data;
      } catch (error) {
        console.error("error fetching patient list", error);
        throw error;
      }
    }
  },

  // fetch patient profile
  async fetchPatientProfile({ commit, state }) {
    try {
      const response = await axios.get(`/data/patient/${state.patientId}`);
      commit("setPatientProfile", response.data);
      commit(
        "setProfilePicture",
        `${process.env.VUE_APP_PROFILE_PIC_BASE_URL}${response.data.person_id}.png`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // update user personal info
  async updateUserPersonalInfo({ commit }, userUpdateCredentials) {
    try {
      const response = await axios.put(
        "/auth/user/update/profile-update",
        userUpdateCredentials
      );
      commit("setUserUpdatePersonalInfo", response.data);
      return response.data;
    } catch (error) {
      console.error("Error user update in ax:", error);
      // throw error;
    }
  },

  async setProfilePicture(_, payload) {
    try {
      const response = await axios.post(
        `/pictures/thumbnails/persons/${payload.id}`,
        payload.formData
      );
      // const image = response.data.thumbnail_path.replace("persons/", "");
      // commit("setProfilePicture", `${process.env.VUE_APP_BASE_URL}/${image}`);
      return response.data;
    } catch (error) {
      console.error("Error user update in axios:", error);
      throw error;
    }
  },

  // update user medical info
  async updateUserMedicalInfo({ commit }, userUpdateCredentials) {
    try {
      const response = await axios.put(
        "/auth/user/update/medical-info",
        userUpdateCredentials
      );
      commit("setUserUpdateMedicalInfo", response.data);
      console.log("User medical in ax", response.data);
      return response.data;
    } catch (error) {
      console.error("Error user update in ax:", error);
      // throw error;
    }
  },

  async getPatientById(_, payload) {
    try {
      const response = await axios.get(`/data/patient/${payload}`);
      console.log("patient payload", payload);
      console.log("Get patient by id:", response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getAllDocumentUploads({ commit }) {
    try {
      const response = await axios.get("/data/documents");
      commit("setAllDocumentUploads", response.data.document_uploads);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async uploadDocument(_, payload) {
    try {
      const response = await axios.post("data/upload/report", payload, {
        headers: {
          accept: "application/json",
          "Content-Type": `multipart/form-data;`,
        },
      });
      console.log("uploadDocument", response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteDocument(_, payload) {
    try {
      const response = await axios.delete(`data/document/delete/${payload}`);
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },

  async dowloadDocumentReport(_, payload) {
    try {
      const response = await axios.get(
        `data/document/patient/download/${payload.patientId}`,
        {
          date_range: {
            start_date: payload.fromDate,
            end_date: payload.toDate,
          },
        }
      );
      console.log("download complete", response);
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },

  async fetchPatientProgressScore({ commit }) {
    try {
      const response = await axios.get(`data/patient/progress_report`);
      console.log("progress score", response);
      commit("setPatientProgressScore", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  // user change password
  async changePassword(_, payload) {
    try {
      await axios.post(`/auth/change-password`, {
        old_password: payload.old_password,
        password: payload.password,
        password_2: payload.password_2,
      });
    } catch (error) {
      throw error;
    }
  },

  // search patient
  async searchPatient(_, payload) {
    const username = payload.username || null;
    try {
      const response = await axios.get(
        `/data/search?keyword=${username}&page=1`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async searchPatientAdmin(_, payload) {
    try {
      const response = await axios.post("/patient/search", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Metrics and untits
  async changeSIUnit(_, payload) {
    try {
      await axios.put("/auth/user/update/medical-info/si-unit", payload);
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
