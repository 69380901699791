<template>
  <div
    class="fixed top-0 left-0 z-[75] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <form
      @submit.prevent="sendInvite"
      class="w-full max-w-[500px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex items-center justify-between mb-6">
          <div class="flex items-center space-x-2 md:space-x-6">
            <p
              @click="closelinkAccountModal"
              class="font-semibold cursor-pointer text-OlsoGrey"
            >
              Account Linking
            </p>
            <DirectionalRightIcon width="8" height="12" />
            <p class="text-ResolutionBlue font-semibold cursor-pointer">
              Send Invite
            </p>
          </div>
          <div class="self-start cursor-pointer" @click="closelinkAccountModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-6 md:space-y-10">
          <div>
            <label for="name" class="text-sm text-BluishGrey">Name</label>
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold pb-5"
              type="text"
              name=""
              :value="result.person?.full_name || result?.name"
              readonly
            />
          </div>

          <div class="relative">
            <label for="country" class="text-BluishGrey text-sm"
              >Relationship*</label
            >
            <select
              id="relationship"
              class="appearance-none md:text-[14px] cursor-pointer w-full border-b border-BlueChalk outline-none text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold pb-5"
              v-model="relationship"
              required
            >
              <option value="select relationship" disabled selected></option>
              <option
                v-if="result.type === 'Patient'"
                v-for="(option, index) in relationshipOptions"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>

              <option v-else value="Test Centre">Test Centre</option>
            </select>
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
            />
          </div>
          <button
            type="submit"
            class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px] w-44"
          >
            <span v-if="isLoading" class="pl-4">
              <LoadingSpinner />
            </span>
            <span v-else>Send invite</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const props = defineProps(["result"]);
const isLoading = ref(false);

const closelinkAccountModal = inject("closelinkAccountModal");

const {
  "accountConnection/sendPatientInvite": sendPatientInvite,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const relationship = ref("");

const relationshipOptions = [
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Neighbour", value: "Neighbour" },
  { label: "Friend", value: "Friend" },
  { label: "Other", value: "Other" },
];

const sendInvite = async () => {
  try {
    isLoading.value = true;
    const response = await sendPatientInvite({
      relationship: relationship.value,
      id: props.result.id,
    });
    if (response) {
      toast.success("Request sent successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
    }
  } catch (error) {
    if (error?.response?.data?.message === "Relationship is missing") {
      toast.error(error?.response?.data?.message, {
        autoClose: 3000,
      });
    } else if (
      error?.response?.data?.error ===
      "You cannot send a connection request to yourself"
    ) {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    } else if (
      error?.response?.data.error === "Connection already established"
    ) {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    } else {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>
