<template>
  <AllModalsWrapper>
    <section class="w-full flex flex-row">
      <Sidebar />

      <div class="h-full w-full lg:w-[calc(100%-100px)] bg-WhiteLilac">
        <Header />

        <router-view></router-view>
      </div>
    </section>
  </AllModalsWrapper>
</template>

<script setup>
import { ref, provide } from "vue";
import AllModalsWrapper from "@/components/main/AllModalsWrapper.vue";
import Header from "@/components/main/header/Header.vue";
import Sidebar from "@/components/main/sidebar/Sidebar.vue";

const isSidebarOpen = ref(false);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

provide("isSidebarOpen", isSidebarOpen);
provide("toggleSidebar", toggleSidebar);
</script>
