<template>
  <div
    class="absolute left-0 top-full z-10 bg-white w-full max-w-[450px] h-[100px] p-4 rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-if="locations.length > 0"
      v-for="(location, index) in locations"
      :key="index"
      class="flex items-center gap-4 cursor-pointer"
      @click="selectLocation(location)"
    >
      <p class="text-DarkJungleGreen text-sm font-medium">
        {{ location }}
      </p>
    </div>
    <div v-else class="h-full flex justify-center items-center gap-4">
      <p class="text-DarkJungleGreen text-sm font-semibold">
        No location found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationsDropdown",
  props: {
    locations: {
      type: Array,
      default: [],
    },
  },
  emits: ["setLocation"],
  setup(_, { emit }) {
    const selectLocation = (value) => {
      emit("setLocation", value);
    };

    return {
      selectLocation,
    };
  },
};
</script>
