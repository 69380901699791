import axios from "@/api";
import { noDP } from "./user";

const state = {
  userSignUp: null,
  user_exist: null,
  userProfile: null,
  isLoggedIn: false,
  isAdmin: false,
};

const getters = {
  getIsAdmin: (state) => {
    return state.isAdmin;
  },
  getuserSignUp: (state) => {
    return state.userSignUp;
  },
  getUserExist: (state) => {
    return state.user_exist;
  },
  getUserProfile: (state) => {
    return state.userProfile;
  },
};

const mutations = {
  setUserSignUp(state, userSignUpData) {
    state.userSignUp = userSignUpData;
  },
  setUserExist(state, payload) {
    state.user_exist = payload;
  },
  setLoginStatus(state, payload) {
    state.isLoggedIn = payload;
  },
  setAdminStatus(state, payload) {
    state.isAdmin = payload;
  },
};

const actions = {
  async signup(_, signupCredentials) {
    try {
      const response = await axios.post("/auth/register", signupCredentials);
      // commit("setUser", response.data.user);
      // commit("setIsAuthenticated", true);
      // console.log("sign up", response.data.user);
      return response.data;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  },

  async signupPatient(_, signupCredentials) {
    try {
      const response = await axios.post(
        "/auth/admin/register-user",
        signupCredentials
      );
      // commit("setUser", response.data.user);
      // commit("setIsAuthenticated", true);
      return response.data;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  },

  async isUserExist({ commit }, userEmail) {
    try {
      const response = await axios.get(`auth/email-exists?email=${userEmail}`);
      console.log("99 in st", response);
      commit("setUserExist", response.data);
      return response.data;
    } catch (error) {
      console.error("userExistError", error);
      throw error;
    }
  },

  async login({ commit }, payload) {
    try {
      const response = await axios.post("/auth/login", payload);
      const data = response.data;
      console.log("data:", data);
      commit("user/setUser", data[0].user, { root: true });
      commit("user/setPatientId", data[0].patient_id, { root: true });
      commit("user/setProfilePercentage", data[0].user.percentage_complete, {
        root: true,
      });
      if (data[0].user.avatar_url) {
        commit("user/setProfilePicture", data[0].user.avatar_url, {
          root: true,
        });
      } else {
        commit("user/setProfilePicture", noDP, { root: true });
      }
      commit("setLoginStatus", data[0].login);
      if (data[0].user.roles.includes("admin")) {
        commit("setAdminStatus", true);
      }
      return response.data;
    } catch (error) {
      // console.error("Error logging in:", error);
      throw error;
    }
  },

  async isAuthenticated({ commit }) {
    try {
      const response = await axios.get("/auth/authenticated");
      const data = response.data;
      // console.log("auth response", data);
      if (data?.authenticated) {
        commit("user/setUser", data.user, { root: true });
        commit("user/setPatientId", data.patient_id, { root: true });
        commit("user/setProfilePercentage", data.user.percentage_complete, {
          root: true,
        });
        if (data.user.avatar_url) {
          commit("user/setProfilePicture", data.user.avatar_url, {
            root: true,
          });
        } else {
          commit("user/setProfilePicture", noDP, { root: true });
        }
        commit("setLoginStatus", true);
        if (data.user.roles.includes("admin")) {
          commit("setAdminStatus", true);
        }
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  // async login(_, payload) {
  //   try {
  //     const response = await axios.post("/auth/login", {
  //       email: payload.email,
  //       password: payload.password,
  //     });

  //     localStorage.setItem("patient_id", response.data[0].patient_id);
  //     // store.commit("user/setUser", response.data.user);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     throw error;
  //   }
  // },

  async logout() {
    try {
      const response = await axios.post("/auth/logout");
      console.log("logout successful in axi", response);
      return response.data;
    } catch (error) {
      console.error("logout successful er axi", error);
      throw error;
    }
  },

  async sendResetToken(_, payload) {
    try {
      await axios.post("/auth/reset-password", payload);
    } catch (error) {
      console.error("Error sending reset token:", error.response.data.message);
      throw error;
    }
  },

  async createNewPassword(_, payload) {
    try {
      const res = await axios.put(
        `/auth/create-new-password?email=${payload.email}&token=${payload.token}`,
        payload.data
      );
    } catch (error) {
      console.error(
        "Error creating new password:",
        error.response.data.message
      );
      throw error;
    }
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
