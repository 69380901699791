<template>
  <div
    class="bg-WhiteLilac border-[0.3px] border-OlsoGrey rounded-xl p-5 md:p-7 h-fit"
  >
    <div class="flex items-center justify-between mb-8">
      <h3 class="text-BalticSea text-base font-bold">Connected Devices</h3>
      <div v-if="getPatientProfile?.medical_devices?.length > 0">
        <a
          href="https://www.turbomedics.com/products"
          target="_blank"
          class="bg-ResolutionBlue py-1 px-3 md:py-2 md:px-4 rounded-[44px] text-white text-xs font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
          >Visit Store Now</a
        >
      </div>
    </div>

    <div>
      <div
        class="space-y-3.5"
        v-if="getPatientProfile?.medical_devices?.length > 0"
      >
        <div
          v-for="(device, index) in getPatientProfile?.medical_devices"
          :key="index"
          class="bg-white py-[10px] px-[14px] rounded-lg flex items-center gap-5"
        >
          <div
            class="bg-white rounded-sm border border-WhiteLilac py-2 px-1.5 flex justify-center items-center"
          >
            <img
              src="../../../../assets/icons/small_device_image1.svg"
              alt=""
            />
          </div>

          <div>
            <p class="text-BluishGrey text-xs">Blood Glucose</p>
            <p class="text-OlsoGrey text-sm font-bold">
              4G Blood Glucose Monitor
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center" v-else>
        <div class="flex flex-col justify-center items-center space-y-2">
          <div
            class="w-fit bg-BlueChalk px-6 py-5 rounded-full flex justify-center items-center"
          >
            <DeviceIcon />
          </div>
          <p class="text-Charcoal text-sm">You have no linked device</p>
          <a
            href="https://www.turbomedics.com/products"
            target="_blank"
            class="bg-ResolutionBlue py-2 px-8 md:py-[13px] md:px-[40px] rounded-[44px] text-white text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            >Visit Store Now</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mapGetters } from "@/hooks/mapStore";
import DeviceIcon from "@/components/icons/DeviceIcon.vue";

const { "user/getPatientProfile": getPatientProfile } = mapGetters();
</script>
