import axios from "@/api";

const state = {
  allPatientRequests: [],
};

const getters = {
  getPatientRequests: (state) => {
    return state.allPatientRequests;
  },
};

const actions = {
  async fetchPatientRequests({ commit }) {
    try {
      const response = await axios.get("/data/patient/connections/list");
      commit("setPatientRequests", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async sendPatientInvite(_, payload) {
    try {
      const response = await axios.post(
        `/data/patient/connection_request/${payload.id}`,
        {
          relationship: payload.relationship,
        }
      );
      console.log("sendPatientInvite response axi", response);
      return response;
    } catch (error) {
      console.error("sendPatientInvite error", error);
      throw error;
    }
  },

  async acceptPatientRequest(_, payload) {
    try {
      const response = await axios.post(
        `/data/patient/connection_response/${payload.id}`,
        {
          response: payload.response,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async unlinkPatientAccount(_, payload) {
    try {
      const response = await axios.delete(
        `/data/patient/connection/cancel/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async cancelSentRequest(_, payload) {
    try {
      const response = await axios.delete(
        `/data/patient/cancel_sent_connection/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getLinkedAccountVitals(_, payload) {
    try {
      const response = await axios.get(
        `/data/patient/glucose_level_list/connected/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getLinkedCenterInfo(_, payload) {
    try {
      const response = await axios.get(
        `/data/patient/health_centre/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  setPatientRequests(state, payload) {
    state.allPatientRequests = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
