<template>
  <div class="w-full md:w-[20%]">
    <div class="flex flex-col items-center form-group">
      <div class="w-32 h-32 rounded-full border">
        <img
          :src="profilePicture"
          class="w-32 h-32 rounded-full object-contain"
        />
      </div>
      <form
        @submit.prevent="handleSubmit"
        class="flex items-center justify-center w-full"
      >
        <label
          for="profilePicture"
          class="flex flex-col items-center justify-center w-full cursor-pointer"
        >
          <div class="flex flex-row items-center justify-center space-x-2 py-4">
            <ReplaceProfileIcon />
            <p class="text-xs text-ResolutionBlue">Upload new picture</p>
          </div>
          <input
            id="profilePicture"
            type="file"
            class="hidden"
            accept="image/*"
            @input="previewImage"
          />
        </label>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useStore } from "vuex";
import ReplaceProfileIcon from "@/components/icons/ReplaceProfileIcon.vue";
import { toast } from "vue3-toastify";

const {
  "user/setProfilePicture": setProfilePicture,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const {
  "user/getPatientProfile": getPatientProfile,
  "user/getUserProfilePicture": profilePicture,
} = mapGetters();

const store = useStore();

const image = ref(null);

const previewImage = async (event) => {
  var input = event.target;
  image.value = input.files[0];
  handleSubmit();
};

const handleSubmit = async () => {
  try {
    const formData = new FormData();
    formData.append("file", image.value);
    await setProfilePicture({
      id: getPatientProfile.value.person.id,
      formData,
    });
    toast.success("Profile picture updated!", {
      autoClose: 1500,
    });
    store.commit("user/setProfilePicture", URL.createObjectURL(image.value));
  } catch (error) {
    toast.error("Error upadting profile picture", {
      autoClose: 1500,
    });
  }
};

onMounted(async () => {
  await fetchPatientProfile();
});
</script>
